@font-face {
  font-family: 'FuturaExtraBlackCondensedRegular';
  src: url('/public/futura-extra-black-condensed-regular.otf')
    format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url('/public/helvetica-neue-medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
